export const signTimesMap = (type?: string): any => {
  if(type === 'map'){
    return {
      First: '首次',
      Second: '二次',
      Forever: '永久',
    }
  }
  return [
    { label: '首次', id: 'First' },
    { label: '二次', id: 'Second' },
    { label: '永久', id: 'Forever' },
  ]
}

export const categoryMap = (type?: string): any => {
  if(type === 'map'){
    return {
      FullTime: '正式合同',
      PartTime: '兼职合同',
      Internship: '实习生合同',
      Termination: '终止',
    }
  }
  return [
    { label: '正式合同', id: 'FullTime' },
    { label: '兼职合同', id: 'PartTime' },
    { label: '实习生合同', id: 'Internship' },
    // { label: '终止', id: 'Termination' },
  ]
}

